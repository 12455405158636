import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/common";
import SEO from "../components/Seo"
import { renderRTF } from "../components/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import SubscribeSection from "../components/subscribe/email";
import SectionWithCards from "../components/content/section-w-cards";
import BlogCard from "../components/blog/blog-card";


const AuthorImage = ({ author }) => {
  const authorImage = getImage(author.avatar)
  return (
    <div className="px-2 w-16 h-16 bg-white relative rounded-full">
      <div className="absolute flex items-center inset-0">
        
        {authorImage ? (
          <GatsbyImage 
            className={` w-full`} 
            image={authorImage} 
            alt={`${author.firstName} ${author.lastName}`}
          />
        ) : (
          <img 
            className="w-full" 
            src={author.avatar.file.url} 
            loading="lazy" alt={`${author.firstName} ${author.lastName}`} />
        )}
      </div>
    </div>
  )
}

const PostSingleTemplate = ({ data }) => {
  const post = data.post.edges[0].node
  // const subscribe = data.subscribe.edges[0].node
  // const card = data.cards.edges[0].node
  const recent = data.recent.edges.map( i => i.node )

  const heroImage = getImage(post.image)

  const { height, width } = post.image.file.details.image

  const aspectRatio = height / width;

  return (
    <Layout>
      <SEO
        title={`${ post.metaTitle }`}
        description={`${ post.metaDescription }`}
        // image={post.image.file.url}
        canonical
      />

      <div className="">
        <div className="py-6 md:py-7 lg:py-8 container">

          <div className="aspect-h-1 aspect-w-1 md:aspect-h-1 lg:aspect-h-1 md:aspect-w-2 lg:aspect-w-3 overflow-hidden w-full relative bg-cx">
            <div className="absolute inset-0 overflow-hidden">
              { aspectRatio >= 0.8 ? (
                <GatsbyImage
                  loading="eager"
                  className="h-full w-auto"
                  image={heroImage}
                  alt={post.image.title}
                  objectFit="contain"
                />
              ) : (
                <GatsbyImage
                  loading="eager"
                  className="h-full w-auto"
                  image={heroImage}
                  alt={post.image.title}
                  objectFit="cover"
                />
              )}

                <GatsbyImage
                  // loading="eager"
                  // className="h-full w-auto"
                  image={heroImage}
                  alt={post.image.title}
                  // objectFit="cover"
                />
              
            </div>
            <div className="absolute inset-0 bg-secondary-1 bg-opacity-50">
            </div>
            <div className="absolute inset-0 flex justify-center items-center">
              <div className="max-w-5xl mx-auto px-4">
                {post.categories && (
                  <div className="text-white font-semibold flex flex-wrap justify-center -mx-2">
                    {post.categories.map(category => {
                      return (
                        <div className="px-2 w-auto"><a className="uppercase text-xs md:text-sm opacity-70 hover:opacity-100" href={`/blog/category/${category.slug}`}>{category.title}</a></div>
                      )
                    })}
                  </div>)
                }
                <h1 className="h2 font-semibold text-center text-white my-2 md:my-4 lg:my-6">{post.heading}</h1>
              </div>
            </div>
          </div>

          <div className="max-w-4xl px-4 mx-auto mt-6 lg:mt-10">
            <div className="flex flex-row items-center -mx-4">
              { post.author.avatar && (
                <AuthorImage author={post.author} />
              )}
             
              <div className="px-2 text-grey-400">
                <div className="font-bold">
                  <span className="">
                  {post.author.firstName}
                  {" "}
                  {post.author.lastName}
                  </span>
                  {" "}
                </div>
                <div className="opacity-60">
                  {post.createdAt}
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="container post-content space-y-7">
          {renderRTF(post.text)}
        </div>

        <div className="container my-8">
          <div className="max-w-4xl mx-auto">
            { post.tags && (
              <div className="flex flex-wrap -mx-2">
                <div className="px-2 flex-nowrap font-semibold text-grey-400">
                  Tagged with:
                </div>
                { post.tags.map( i => {
                  return (
                    <div className="px-2 flex-nowrap">
                      <a
                        href={`/blog/tag/${i.slug}`}
                        className="text-blue-400 hover:underline uppercase">
                        {i.title}
                      </a>
                    </div>
                  )
                })}
              </div>
              )
            }
          </div>
        </div>
      
        <div className="space-y-20 md:space-y-28 mt-20 md:mt-24">
          <div className="container max-w-4xl pb-20 ">
            <div className="flex flex-wrap -mx-4">
              { post.author.avatar && (
                <AuthorImage author={post.author} />
              )}
              <div className="px-4 w-full text-white md:w-auto flex-1 space-y-3">
                <p className="text-lg lg:text-xl mt-2 font-medium text-grey-300">
                  {post.author.firstName}
                  {" "}
                  {post.author.lastName}
                </p>
                {post.author.bio && renderRTF(post.author.bio)}
              </div>
            </div>
          </div>
          {/* <div className="page-content"> */}
            {/* <SectionWithCards data={card} /> */}
            {/* <SubscribeSection data={subscribe} /> */}
          {/* </div> */}

          {Array.from(recent).length > 0 && (
            <section className="pb-24 lg:pb-32">
              <div className="container">
                <p className="text-center h3 font-semibold text-white">Other blog posts</p>

                <div className="flex flex-wrap -mx-4">
                  {recent.map( i => {
                    return (
                      <div
                        className="px-4 w-full md:w-1/2 lg:w-1/3 my-6">
                        <BlogCard post={i} />
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
          )}
          
          
        </div>
      </div>
    </Layout>
  )
}

export default PostSingleTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    post: allContentfulPost(filter: { slug: { eq: $slug }}) {
      edges {
        node {
          author {
            name
            lastName
            firstName
            bio {
              raw
            }
            slug
            avatar {
              gatsbyImageData(
                width: 192
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
              title
              description
              file {
                url
              }
            }
          }
          tags {
            title
            slug
          }
          categories {
            title
            slug
          }
          metaTitle
          metaDescription
          updatedAt(formatString: "MMMM DD yyyy")
          createdAt(formatString: "MMMM DD yyyy")
          heading
          slug
          image {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
            gatsbyImageData(
              width: 1510
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
            title
          }
          text {
            raw
            # references {
            #   ... on ContentfulAsset {
            #     contentful_id
            #     __typename
            #     title
            #     gatsbyImageData(
            #       layout: FULL_WIDTH
            #       placeholder: BLURRED
            #       formats: [AUTO, WEBP, AVIF]
            #     )
            #     file {
            #       url
            #       fileName
            #     }
            #   }
            # }
          }
          
        }
      }
    }
    recent: allContentfulPost(
        filter: { slug: { ne: $slug }}
        sort: { fields: date, order: DESC }, 
        limit: 3
      ) 
      {
      edges {
        node {
          createdAt(formatString: "MMMM DD yyyy")
          heading
          slug
          image {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }

    # subscribe: allContentfulSectionSubscribe(filter: {id: { eq: "bf84c2eb-8d93-52e9-9a31-49ab034469e3"}}) {
    #   edges {
    #     node {
    #       text {
    #         raw
    #       }
    #       backgroundColor
    #     }
    #   }
    # }
    # cards: allContentfulSectionCards(filter: {id: { eq: "5a043cb3-6515-597a-92cd-2bf0083a47bb"}}) {
    #   edges {
    #     node {
    #       id
    #       name
    #       cardCentred
    #       cardType
    #       text {
    #         raw
    #         references {
    #           ... on ContentfulAsset {
    #             contentful_id
    #             __typename
    #             title
    #             file {
    #               url
    #               fileName
    #             }
    #           }
    #         }
    #       }
    #       backgroundColor
    #       cards {
    #         heading
    #         subHeading
    #         icon {
    #           file {
    #             url
    #           }
    #           title
    #         }
    #         link
    #       }
    #     }
    #   }
    # }
  }
`
