

import React, { useEffect, useState } from "react";
import { renderSecondaryRTF } from "../rich-text";
// import addToMailchimp from 'gatsby-plugin-mailchimp'

function Spinner() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
      viewBox="0 0 24 24"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
        className="opacity-25"
      ></circle>
      <path
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        className="opacity-75"
      ></path>
    </svg>
  );
}

const LegacyPlaceholderForm = ({hasWhiteText}) => {
  const [ pathName, setPathname ] = useState('')
  
  const [email, setEmail] = useState('');
  const [result, setResult] = useState(null);
  const [listFields, setListFields] = useState();

  const [isProcessing, setProcessing] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const _handleSubmit = async (e) => {
    e.preventDefault();
    // make disabled and show spinner

    setProcessing(true);

    // const result = await addToMailchimp(email, listFields)
    // I recommend setting `result` to React state
    // but you can do whatever you want
    setProcessing(false);
    setResult(result);

    setTimeout(() => {
      setResult(null)
    }, 2000)

    if (result.result === 'success') {
      setEmail('');
    }
  }

  useEffect( () => {
    setPathname(window.location.pathname);
    setListFields({
      PATHNAME: pathName
    });
  }, [pathName])

  return (
    <>
      <form className="flex flex-wrap -mx-4 space-y-4 lg:space-y-0 text-right relative" onSubmit={_handleSubmit}>
        <div className="px-4 w-full md:w-auto flex-grow text-left relative">
          <div className="absolute -left-3 top-1/2">
            {isProcessing && <Spinner />}
          </div>
          <label className="block cursor-pointer border-b border-white">Email
          <input 
            className={`${isProcessing && 'opacity-50'}  ${ hasWhiteText ? " bg-transparent border-b-2 border-white focus:border-white" : "rounded-lg border-secondary border pl-5" } pr-5 py-3 w-full focus:outline-none`}
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="" 
            required
            disabled={isProcessing}
            />
          </label>
        </div>
        <div className="px-4 w-full md:w-auto flex items-center"><button disabled={isProcessing} className={`${isProcessing && 'opacity-50'} btn btn-white`} type="submit">Subscribe</button></div>
        <div className="absolute h-4 left-4 -bottom-6">{" "} {result && result.msg}</div>
      </form>
    </>
   

  )
}

const SubscribeSection = ({ data }) => {

  const textColor = data.backgroundColor === 'bg-secondary' || data.backgroundColor === 'bg-accent' ? 'text-white' : 'text-secondary';
  const bg = data.backgroundColor === 'bg-blue-light' ? '' : `${data.backgroundColor} section-xs`
  return (
    <section className={`section-md px-4`}>
      <div className={`${bg} ${textColor} rounded-20 lg:rounded-40 max-w-full md:max-w-7xl container relative overflow-hidden`}>
        <div className="max-w-5xl mx-auto">
          <div
            className={`flex-wrap items-center justify-center md:justify-between flex -mx-4`}
          >
            <div className={`w-full md:w-1/2 px-4 text-center md:text-left`}>
              {renderSecondaryRTF(data.text)}
            </div>
            <div className={`w-auto md:w-1/2 max-w-xs md:max-w-full text-center md:text-left md:w-auto p-4`}>
              <LegacyPlaceholderForm hasWhiteText={true} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SubscribeSection